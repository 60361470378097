// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".SourceCode-module__jwA8xLvYorZO_yZcQeaZ6 {\n  position: relative;\n}\n\n.SourceCode-module__11HCESttG8LPmBAjdyc1Hq {\n  overflow-y: auto;\n}\n\n.SourceCode-module__3NS_LYPYRRyOb2h19GK0v0 {\n  max-height: 400px;\n}\n\n.SourceCode-module__jwA8xLvYorZO_yZcQeaZ6 .SourceCode-module__AvwEv6qhdoLkJcHN7maEb {\n  position: absolute;\n  top: 15px;\n  right: 15px;\n  opacity: 0;\n  transition: opacity 0.2s ease;\n}\n\n.SourceCode-module__jwA8xLvYorZO_yZcQeaZ6:hover .SourceCode-module__AvwEv6qhdoLkJcHN7maEb {\n  opacity: 1;\n}\n\n.SourceCode-module__jwA8xLvYorZO_yZcQeaZ6 pre {\n  border-radius: 2px;\n  padding: 12px 20px;\n}\n", "",{"version":3,"sources":["SourceCode.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,UAAU;EACV,6BAA6B;AAC/B;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;AACpB","file":"SourceCode.module.css","sourcesContent":[".root {\n  position: relative;\n}\n\n.scroller {\n  overflow-y: auto;\n}\n\n.scroller_max-height {\n  max-height: 400px;\n}\n\n.root .button {\n  position: absolute;\n  top: 15px;\n  right: 15px;\n  opacity: 0;\n  transition: opacity 0.2s ease;\n}\n\n.root:hover .button {\n  opacity: 1;\n}\n\n.root pre {\n  border-radius: 2px;\n  padding: 12px 20px;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "SourceCode-module__jwA8xLvYorZO_yZcQeaZ6",
	"scroller": "SourceCode-module__11HCESttG8LPmBAjdyc1Hq",
	"scroller_max-height": "SourceCode-module__3NS_LYPYRRyOb2h19GK0v0",
	"button": "SourceCode-module__AvwEv6qhdoLkJcHN7maEb"
};
module.exports = exports;
