// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".MaintenanceForm-module__27_ZG5QXQb_EUKCuHyp-Vm {\n  display: block;\n}\n\n.MaintenanceForm-module__pcPxnlPfQzcoFI6GV4i-x {\n  margin: 16px 0 0 16px;\n}\n\n.MaintenanceForm-module__2vDAdfIRbTeZRHPlY-8Kf1 {\n  margin: 4px 4px 400px 4px;\n}\n", "",{"version":3,"sources":["MaintenanceForm.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;AAC3B","file":"MaintenanceForm.module.css","sourcesContent":[".root {\n  display: block;\n}\n\n.title {\n  margin: 16px 0 0 16px;\n}\n\n.content {\n  margin: 4px 4px 400px 4px;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "MaintenanceForm-module__27_ZG5QXQb_EUKCuHyp-Vm",
	"title": "MaintenanceForm-module__pcPxnlPfQzcoFI6GV4i-x",
	"content": "MaintenanceForm-module__2vDAdfIRbTeZRHPlY-8Kf1"
};
module.exports = exports;
