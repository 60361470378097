// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".IntegrationSettings-module__2i97CnLWJba8D1_kCc6r8Z {\n  display: block;\n}\n\n.IntegrationSettings-module__3Io9ofw9-_gpl6oc2c2XvH {\n  padding: 24px;\n  display: flex;\n  gap: 10px;\n  align-items: flex-start;\n}\n\n.IntegrationSettings-module__3chHRSLYxKL9tjepAyQMpi {\n  display: flex;\n  flex-direction: column;\n}\n\n.IntegrationSettings-module__3DNqb79JNrsd7H16Xevlfh {\n  margin-top: 10px;\n}\n\n.IntegrationSettings-module__3DNqb79JNrsd7H16Xevlfh ul {\n  list-style-type: none;\n}\n\n.IntegrationSettings-module__3DNqb79JNrsd7H16Xevlfh ol {\n  list-style-type: none;\n}\n\n.IntegrationSettings-module__mMm6gowHbiwwAakKI5AdK {\n  right: 0;\n  position: absolute;\n}\n", "",{"version":3,"sources":["IntegrationSettings.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,aAAa;EACb,SAAS;EACT,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,QAAQ;EACR,kBAAkB;AACpB","file":"IntegrationSettings.module.css","sourcesContent":[".root {\n  display: block;\n}\n\n.title {\n  padding: 24px;\n  display: flex;\n  gap: 10px;\n  align-items: flex-start;\n}\n\n.title-column {\n  display: flex;\n  flex-direction: column;\n}\n\n.content {\n  margin-top: 10px;\n}\n\n.content ul {\n  list-style-type: none;\n}\n\n.content ol {\n  list-style-type: none;\n}\n\n.settings-header-buttons {\n  right: 0;\n  position: absolute;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "IntegrationSettings-module__2i97CnLWJba8D1_kCc6r8Z",
	"title": "IntegrationSettings-module__3Io9ofw9-_gpl6oc2c2XvH",
	"title-column": "IntegrationSettings-module__3chHRSLYxKL9tjepAyQMpi",
	"content": "IntegrationSettings-module__3DNqb79JNrsd7H16Xevlfh",
	"settings-header-buttons": "IntegrationSettings-module__mMm6gowHbiwwAakKI5AdK"
};
module.exports = exports;
