// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".GList-module__1MF4MWKBxRXleZLKgggvoM {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.GList-module__1Gp_JctCQePMgtK3Pod8lo {\n  padding: 16px;\n  width: 100%;\n  border-bottom: var(--border);\n  position: relative;\n  cursor: pointer;\n}\n\n.GList-module__1Gp_JctCQePMgtK3Pod8lo:first-child {\n  border-top: var(--border);\n}\n\n.GList-module__1Gp_JctCQePMgtK3Pod8lo:hover {\n  background: var(--secondary-background);\n}\n\n.GList-module__Dd52EuSDD5VsvcerGe5DS {\n  cursor: default;\n  background: var(--secondary-background);\n}\n\n.GList-module__Dd52EuSDD5VsvcerGe5DS::before {\n  display: block;\n  content: '';\n  position: absolute;\n  left: 0;\n  top: 0;\n  bottom: 0;\n  width: 4px;\n  background-image: linear-gradient(270deg, #f55f3e 0%, #f83 100%);\n}\n", "",{"version":3,"sources":["GList.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,4BAA4B;EAC5B,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,eAAe;EACf,uCAAuC;AACzC;;AAEA;EACE,cAAc;EACd,WAAW;EACX,kBAAkB;EAClB,OAAO;EACP,MAAM;EACN,SAAS;EACT,UAAU;EACV,gEAAgE;AAClE","file":"GList.module.css","sourcesContent":[".root {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.item {\n  padding: 16px;\n  width: 100%;\n  border-bottom: var(--border);\n  position: relative;\n  cursor: pointer;\n}\n\n.item:first-child {\n  border-top: var(--border);\n}\n\n.item:hover {\n  background: var(--secondary-background);\n}\n\n.item_selected {\n  cursor: default;\n  background: var(--secondary-background);\n}\n\n.item_selected::before {\n  display: block;\n  content: '';\n  position: absolute;\n  left: 0;\n  top: 0;\n  bottom: 0;\n  width: 4px;\n  background-image: linear-gradient(270deg, #f55f3e 0%, #f83 100%);\n}\n"]}]);
// Exports
exports.locals = {
	"root": "GList-module__1MF4MWKBxRXleZLKgggvoM",
	"item": "GList-module__1Gp_JctCQePMgtK3Pod8lo",
	"item_selected": "GList-module__Dd52EuSDD5VsvcerGe5DS"
};
module.exports = exports;
