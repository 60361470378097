// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".EscalationsFilters-module__1umpw1t8ZQdDXyu2ilNS5- {\n  display: flex;\n  justify-content: space-between;\n}\n\n.EscalationsFilters-module__3F6hZZwWAlb9mkjVuhLQDA {\n  max-width: 400px;\n}\n", "",{"version":3,"sources":["EscalationsFilters.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,gBAAgB;AAClB","file":"EscalationsFilters.module.css","sourcesContent":[".root {\n  display: flex;\n  justify-content: space-between;\n}\n\n.search {\n  max-width: 400px;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "EscalationsFilters-module__1umpw1t8ZQdDXyu2ilNS5-",
	"search": "EscalationsFilters-module__3F6hZZwWAlb9mkjVuhLQDA"
};
module.exports = exports;
