// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".DefaultPageLayout-module__3N2OsdKvOW3m3IF5MdGyfk {\n  margin-top: -24px;\n}\n\n.DefaultPageLayout-module__3N2OsdKvOW3m3IF5MdGyfk .DefaultPageLayout-module__2BcA_8a8P54PtR59SVCz3x {\n  display: flex;\n  gap: 10px;\n}\n\n.DefaultPageLayout-module__3N2OsdKvOW3m3IF5MdGyfk .DefaultPageLayout-module__Xv5sBAWWtz1a41WMbycyi {\n  margin: 24px 0;\n}\n\n/* --- GRAFANA UI TUNINGS --- */\n\n.DefaultPageLayout-module__3N2OsdKvOW3m3IF5MdGyfk .filter-table td {\n  white-space: break-spaces;\n  line-height: 20px;\n  height: auto;\n}\n", "",{"version":3,"sources":["DefaultPageLayout.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,cAAc;AAChB;;AAEA,+BAA+B;;AAE/B;EACE,yBAAyB;EACzB,iBAAiB;EACjB,YAAY;AACd","file":"DefaultPageLayout.module.css","sourcesContent":[".root {\n  margin-top: -24px;\n}\n\n.root .alerts_horizontal {\n  display: flex;\n  gap: 10px;\n}\n\n.root .alert {\n  margin: 24px 0;\n}\n\n/* --- GRAFANA UI TUNINGS --- */\n\n.root :global(.filter-table) td {\n  white-space: break-spaces;\n  line-height: 20px;\n  height: auto;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "DefaultPageLayout-module__3N2OsdKvOW3m3IF5MdGyfk",
	"alerts_horizontal": "DefaultPageLayout-module__2BcA_8a8P54PtR59SVCz3x",
	"alert": "DefaultPageLayout-module__Xv5sBAWWtz1a41WMbycyi"
};
module.exports = exports;
