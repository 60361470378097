// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Timeline-module__8CLvr0PTWhlDMfijM-k5S {\n  padding: 0;\n  margin: 0;\n  list-style: none;\n}\n\n.Timeline-module__1-WdHkYSNCamAgpZq24KoB {\n  display: flex;\n  align-items: center;\n  margin: 10px 0;\n}\n\n.Timeline-module__YFKzW78PM8HC3JXG6gRMA {\n  width: 28px;\n  height: 28px;\n  border-radius: 50%;\n  text-align: center;\n  line-height: 28px;\n  font-size: 14px;\n  font-weight: 400;\n  color: white;\n  flex-shrink: 0;\n}\n\n.Timeline-module___rDu_12U-8BWWtLqUMGsA {\n  margin: 0 0 0 24px;\n  word-break: break-word;\n  flex-grow: 1;\n}\n", "",{"version":3,"sources":["Timeline.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,sBAAsB;EACtB,YAAY;AACd","file":"Timeline.module.css","sourcesContent":[".root {\n  padding: 0;\n  margin: 0;\n  list-style: none;\n}\n\n.item {\n  display: flex;\n  align-items: center;\n  margin: 10px 0;\n}\n\n.dot {\n  width: 28px;\n  height: 28px;\n  border-radius: 50%;\n  text-align: center;\n  line-height: 28px;\n  font-size: 14px;\n  font-weight: 400;\n  color: white;\n  flex-shrink: 0;\n}\n\n.content {\n  margin: 0 0 0 24px;\n  word-break: break-word;\n  flex-grow: 1;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "Timeline-module__8CLvr0PTWhlDMfijM-k5S",
	"item": "Timeline-module__1-WdHkYSNCamAgpZq24KoB",
	"dot": "Timeline-module__YFKzW78PM8HC3JXG6gRMA",
	"content": "Timeline-module___rDu_12U-8BWWtLqUMGsA"
};
module.exports = exports;
