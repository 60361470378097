// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".SchedulesFilters-module__3VHtx1l1-0XgT3gyfaAIVX {\n  display: inline-flex;\n  align-items: center;\n}\n", "",{"version":3,"sources":["SchedulesFilters.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,mBAAmB;AACrB","file":"SchedulesFilters.module.css","sourcesContent":[".root {\n  display: inline-flex;\n  align-items: center;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "SchedulesFilters-module__3VHtx1l1-0XgT3gyfaAIVX"
};
module.exports = exports;
