// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Tag-module__2XsN7-I4LvvncWRhlXJlCd {\n  border-radius: 4px;\n  padding: 1px 7px 4px 7px;\n  color: white;\n}\n", "",{"version":3,"sources":["Tag.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,wBAAwB;EACxB,YAAY;AACd","file":"Tag.module.css","sourcesContent":[".root {\n  border-radius: 4px;\n  padding: 1px 7px 4px 7px;\n  color: white;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "Tag-module__2XsN7-I4LvvncWRhlXJlCd"
};
module.exports = exports;
