// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".ChannelFilterForm-module__1v3NBnn9PYElJCzgSdqICj {\n  margin: 20px 0;\n}\n\n.ChannelFilterForm-module__1hOqPAdfXppIkzABOM_gxI {\n  margin: 20px 0;\n}\n", "",{"version":3,"sources":["ChannelFilterForm.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB","file":"ChannelFilterForm.module.css","sourcesContent":[".form {\n  margin: 20px 0;\n}\n\n.incident-matcher {\n  margin: 20px 0;\n}\n"]}]);
// Exports
exports.locals = {
	"form": "ChannelFilterForm-module__1v3NBnn9PYElJCzgSdqICj",
	"incident-matcher": "ChannelFilterForm-module__1hOqPAdfXppIkzABOM_gxI"
};
module.exports = exports;
