// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".ScheduleIcalLink-module__3nxdS_uJxECyPX7E2rYIsL {\n  background-color: var(--secondary-background);\n}\n", "",{"version":3,"sources":["ScheduleIcalLink.module.css"],"names":[],"mappings":"AAAA;EACE,6CAA6C;AAC/C","file":"ScheduleIcalLink.module.css","sourcesContent":[".link-container {\n  background-color: var(--secondary-background);\n}\n"]}]);
// Exports
exports.locals = {
	"link-container": "ScheduleIcalLink-module__3nxdS_uJxECyPX7E2rYIsL"
};
module.exports = exports;
