// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".IntegrationsFilters-module__3qv23qWzIol_amnjOzzoN- {\n  display: block;\n}\n\n.IntegrationsFilters-module__3GDutljpSpJk8eckPQLY4 {\n  display: flex;\n  position: relative;\n}\n\n.IntegrationsFilters-module__vyjrEC4mXQyTNx9Ocnwea {\n  position: absolute;\n  right: 0;\n}\n\n.IntegrationsFilters-module__8YNzO65bkXFaVTzCm4nE4 {\n  max-width: 400px;\n}\n", "",{"version":3,"sources":["IntegrationsFilters.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;AACV;;AAEA;EACE,gBAAgB;AAClB","file":"IntegrationsFilters.module.css","sourcesContent":[".root {\n  display: block;\n}\n\n.integrationsFilters {\n  display: flex;\n  position: relative;\n}\n\n.searchIntegrationClear {\n  position: absolute;\n  right: 0;\n}\n\n.searchIntegrationInput {\n  max-width: 400px;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "IntegrationsFilters-module__3qv23qWzIol_amnjOzzoN-",
	"integrationsFilters": "IntegrationsFilters-module__3GDutljpSpJk8eckPQLY4",
	"searchIntegrationClear": "IntegrationsFilters-module__vyjrEC4mXQyTNx9Ocnwea",
	"searchIntegrationInput": "IntegrationsFilters-module__8YNzO65bkXFaVTzCm4nE4"
};
module.exports = exports;
