// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".MonacoJinja2Editor-module__3KBTWHpISm7MNNKiLb2X3l {\n  display: block;\n}\n", "",{"version":3,"sources":["MonacoJinja2Editor.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB","file":"MonacoJinja2Editor.module.css","sourcesContent":[".root {\n  display: block;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "MonacoJinja2Editor-module__3KBTWHpISm7MNNKiLb2X3l"
};
module.exports = exports;
