// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Integrations-module__3bcujrJn2uiazbk1lH6IBk {\n  margin-top: 24px;\n}\n\n.Integrations-module__3S2gS-eatIuzVRltJaHKB0 {\n  margin-bottom: 20px;\n}\n\n.Integrations-module__1WvUzQZIi84iabe7HNCsxi {\n  width: 100%;\n  display: flex;\n  align-items: flex-start;\n  border: var(--border);\n  border-radius: 2px;\n}\n\n.Integrations-module__E-BZMs38UtfH_EjqVVKFJ {\n  width: 100%;\n  padding: 50px 0;\n}\n\n.Integrations-module__2gxjofvoUySHXIL1J2WA7W {\n  width: 400px;\n  flex-shrink: 0;\n  overflow: auto;\n  max-height: 70vh;\n}\n\n.Integrations-module__3cPaAg0GPls15DDKi3QQez {\n  margin: 16px;\n  flex-grow: 1;\n}\n\n.Integrations-module__KRpPL1PhH1nhzmgReT52W {\n  margin: 16px;\n}\n\n.Integrations-module__16iFfxw-8RQC5Gb_tSLqb {\n  border-right: var(--border);\n}\n", "",{"version":3,"sources":["Integrations.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,cAAc;EACd,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,2BAA2B;AAC7B","file":"Integrations.module.css","sourcesContent":[".root {\n  margin-top: 24px;\n}\n\n.filters {\n  margin-bottom: 20px;\n}\n\n.integrations {\n  width: 100%;\n  display: flex;\n  align-items: flex-start;\n  border: var(--border);\n  border-radius: 2px;\n}\n\n.no-integrations {\n  width: 100%;\n  padding: 50px 0;\n}\n\n.alert-receive-channels-list {\n  width: 400px;\n  flex-shrink: 0;\n  overflow: auto;\n  max-height: 70vh;\n}\n\n.alert-rules {\n  margin: 16px;\n  flex-grow: 1;\n}\n\n.newIntegrationButton {\n  margin: 16px;\n}\n\n.integrationsList {\n  border-right: var(--border);\n}\n"]}]);
// Exports
exports.locals = {
	"root": "Integrations-module__3bcujrJn2uiazbk1lH6IBk",
	"filters": "Integrations-module__3S2gS-eatIuzVRltJaHKB0",
	"integrations": "Integrations-module__1WvUzQZIi84iabe7HNCsxi",
	"no-integrations": "Integrations-module__E-BZMs38UtfH_EjqVVKFJ",
	"alert-receive-channels-list": "Integrations-module__2gxjofvoUySHXIL1J2WA7W",
	"alert-rules": "Integrations-module__3cPaAg0GPls15DDKi3QQez",
	"newIntegrationButton": "Integrations-module__KRpPL1PhH1nhzmgReT52W",
	"integrationsList": "Integrations-module__16iFfxw-8RQC5Gb_tSLqb"
};
module.exports = exports;
