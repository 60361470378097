// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".SettingsPage-module__2nR__UHzI0l2kLJ7LpKdek {\n  margin-top: 24px;\n}\n\n.SettingsPage-module__1igY0oghdM5iGLfG4NVnRR {\n  margin-bottom: 20px;\n}\n\n.SettingsPage-module__-xt1Rd_fjvfNGOrSTtO71 {\n  width: fit-content;\n}\n", "",{"version":3,"sources":["SettingsPage.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB","file":"SettingsPage.module.css","sourcesContent":[".root {\n  margin-top: 24px;\n}\n\n.title {\n  margin-bottom: 20px;\n}\n\n.settings {\n  width: fit-content;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "SettingsPage-module__2nR__UHzI0l2kLJ7LpKdek",
	"title": "SettingsPage-module__1igY0oghdM5iGLfG4NVnRR",
	"settings": "SettingsPage-module__-xt1Rd_fjvfNGOrSTtO71"
};
module.exports = exports;
