// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".UserInfoTab-module__1hDkuMPXjwmubxq5N9_-5 {\n  font-size: 16px;\n}\n\n.UserInfoTab-module__3r7A2IltkvcHVBqBlh5FDN {\n  margin-bottom: 15px;\n}\n", "",{"version":3,"sources":["UserInfoTab.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,mBAAmB;AACrB","file":"UserInfoTab.module.css","sourcesContent":[".user-value {\n  font-size: 16px;\n}\n\n.user-item {\n  margin-bottom: 15px;\n}\n"]}]);
// Exports
exports.locals = {
	"user-value": "UserInfoTab-module__1hDkuMPXjwmubxq5N9_-5",
	"user-item": "UserInfoTab-module__3r7A2IltkvcHVBqBlh5FDN"
};
module.exports = exports;
