// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Maintenance-module__1gIYTrl0hyof6Cy1ykz9Vl {\n  margin-top: 24px;\n}\n\n.Maintenance-module__3vDxU1v6bvc9FAedjIonj7 {\n  width: 400px;\n}\n\n.Maintenance-module__2LFaxEPVO47kSrcG2xHRw5 {\n  display: flex;\n  justify-content: space-between;\n}\n", "",{"version":3,"sources":["Maintenance.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC","file":"Maintenance.module.css","sourcesContent":[".root {\n  margin-top: 24px;\n}\n\n.select {\n  width: 400px;\n}\n\n.header {\n  display: flex;\n  justify-content: space-between;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "Maintenance-module__1gIYTrl0hyof6Cy1ykz9Vl",
	"select": "Maintenance-module__3vDxU1v6bvc9FAedjIonj7",
	"header": "Maintenance-module__2LFaxEPVO47kSrcG2xHRw5"
};
module.exports = exports;
