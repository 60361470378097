// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Policy-module__1E2VyEXTg4VALlbTWt7gNm {\n  white-space: nowrap;\n}\n\n.Policy-module__1I7EySZL500IgUT5qYEnoc {\n  padding-right: 6px;\n}\n\n.Policy-module__2BMtKKGIPrIcskLArB5HsU {\n  max-width: none !important;\n}\n\n.Policy-module__2Hv7DtLqmqMdshpYO8GbAV {\n  margin-right: 10px !important;\n}\n\n.Policy-module__HuVm06iAnX_5qzpNiR7kp {\n  cursor: move;\n}\n\n.Policy-module__BLD0AlT11Mt1FOe9AKk4o {\n  cursor: not-allowed;\n  pointer-events: none;\n}\n", "",{"version":3,"sources":["Policy.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,oBAAoB;AACtB","file":"Policy.module.css","sourcesContent":[".note {\n  white-space: nowrap;\n}\n\n.note-icon {\n  padding-right: 6px;\n}\n\n.tooltip {\n  max-width: none !important;\n}\n\n.control {\n  margin-right: 10px !important;\n}\n\n.handle {\n  cursor: move;\n}\n\n.handle_disabled {\n  cursor: not-allowed;\n  pointer-events: none;\n}\n"]}]);
// Exports
exports.locals = {
	"note": "Policy-module__1E2VyEXTg4VALlbTWt7gNm",
	"note-icon": "Policy-module__1I7EySZL500IgUT5qYEnoc",
	"tooltip": "Policy-module__2BMtKKGIPrIcskLArB5HsU",
	"control": "Policy-module__2Hv7DtLqmqMdshpYO8GbAV",
	"handle": "Policy-module__HuVm06iAnX_5qzpNiR7kp",
	"handle_disabled": "Policy-module__BLD0AlT11Mt1FOe9AKk4o"
};
module.exports = exports;
