// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".GForm-module__2xsDGGM-LUnerfD3RSrgxZ {\n  display: block;\n}\n", "",{"version":3,"sources":["GForm.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB","file":"GForm.module.css","sourcesContent":[".root {\n  display: block;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "GForm-module__2xsDGGM-LUnerfD3RSrgxZ"
};
module.exports = exports;
