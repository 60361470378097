// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".HeartbeatForm-module__21levbvF9OSDudcU_2lTTO {\n  width: 200px;\n}\n\n.HeartbeatForm-module__2y23wI-KcHEmU9FfzFNEq7 {\n  width: 14px;\n  height: 14px;\n  border-radius: 50%;\n  margin-right: 8px;\n  display: inline-block;\n  background-color: grey;\n}\n\n.HeartbeatForm-module__1DEHF7F-lFjaOmsrkhU34x {\n  background-color: #ff4d4f;\n}\n\n.HeartbeatForm-module__21F5w0aeIyX5toSURa1iOD {\n  background-color: #73d13d;\n}\n\n.HeartbeatForm-module__2o1aFh1OXpmQsaapFwn6Pb .HeartbeatForm-module__21levbvF9OSDudcU_2lTTO {\n  width: 250px;\n  display: inline-block;\n}\n\n.HeartbeatForm-module__2THaFBpn_-DByvMLyu4BTP {\n  margin-top: 20px;\n}\n", "",{"version":3,"sources":["HeartbeatForm.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,qBAAqB;EACrB,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;AAClB","file":"HeartbeatForm.module.css","sourcesContent":[".select {\n  width: 200px;\n}\n\n.heartbeat-button {\n  width: 14px;\n  height: 14px;\n  border-radius: 50%;\n  margin-right: 8px;\n  display: inline-block;\n  background-color: grey;\n}\n\n.heartbeat-button_false {\n  background-color: #ff4d4f;\n}\n\n.heartbeat-button_true {\n  background-color: #73d13d;\n}\n\n.root .select {\n  width: 250px;\n  display: inline-block;\n}\n\n.buttons {\n  margin-top: 20px;\n}\n"]}]);
// Exports
exports.locals = {
	"select": "HeartbeatForm-module__21levbvF9OSDudcU_2lTTO",
	"heartbeat-button": "HeartbeatForm-module__2y23wI-KcHEmU9FfzFNEq7",
	"heartbeat-button_false": "HeartbeatForm-module__1DEHF7F-lFjaOmsrkhU34x",
	"heartbeat-button_true": "HeartbeatForm-module__21F5w0aeIyX5toSURa1iOD",
	"root": "HeartbeatForm-module__2o1aFh1OXpmQsaapFwn6Pb",
	"buttons": "HeartbeatForm-module__2THaFBpn_-DByvMLyu4BTP"
};
module.exports = exports;
