// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".CloudPhoneSettings-module__2pCuSSO2DAF69iDs_LA6wz {\n  color: grey;\n}\n", "",{"version":3,"sources":["CloudPhoneSettings.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb","file":"CloudPhoneSettings.module.css","sourcesContent":[".test {\n  color: grey;\n}\n"]}]);
// Exports
exports.locals = {
	"test": "CloudPhoneSettings-module__2pCuSSO2DAF69iDs_LA6wz"
};
module.exports = exports;
