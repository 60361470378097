// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".CardButton-module__26wByBmUJN9VPRyIQc41VF {\n  height: 88px;\n  position: relative;\n  cursor: pointer;\n}\n\n.CardButton-module__rySU1Ez3utzdUfp_ML8nq {\n  position: absolute;\n  left: 20px;\n  top: 20px;\n  color: var(--disabled-text-color);\n}\n\n.CardButton-module__3lQIO1nV8xXXHM-cnsdw-S {\n  position: absolute;\n  top: 14px;\n  left: 90px;\n}\n\n.CardButton-module__DqyHPrV5Fqr5fr73Ai1R5::before {\n  display: block;\n  content: \"\";\n  position: absolute;\n  left: 0;\n  top: 0;\n  bottom: 0;\n  width: 4px;\n  background-image: linear-gradient(270deg, #f55f3e 0%, #f83 100%);\n}\n", "",{"version":3,"sources":["CardButton.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,SAAS;EACT,iCAAiC;AACnC;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,cAAc;EACd,WAAW;EACX,kBAAkB;EAClB,OAAO;EACP,MAAM;EACN,SAAS;EACT,UAAU;EACV,gEAAgE;AAClE","file":"CardButton.module.css","sourcesContent":[".root {\n  height: 88px;\n  position: relative;\n  cursor: pointer;\n}\n\n.icon {\n  position: absolute;\n  left: 20px;\n  top: 20px;\n  color: var(--disabled-text-color);\n}\n\n.meta {\n  position: absolute;\n  top: 14px;\n  left: 90px;\n}\n\n.root_selected::before {\n  display: block;\n  content: \"\";\n  position: absolute;\n  left: 0;\n  top: 0;\n  bottom: 0;\n  width: 4px;\n  background-image: linear-gradient(270deg, #f55f3e 0%, #f83 100%);\n}\n"]}]);
// Exports
exports.locals = {
	"root": "CardButton-module__26wByBmUJN9VPRyIQc41VF",
	"icon": "CardButton-module__rySU1Ez3utzdUfp_ML8nq",
	"meta": "CardButton-module__3lQIO1nV8xXXHM-cnsdw-S",
	"root_selected": "CardButton-module__DqyHPrV5Fqr5fr73Ai1R5"
};
module.exports = exports;
