// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".OrganizationLog-module__mEsdDzxwvzaZUO1-IiiSY {\n  margin-top: 24px;\n}\n\n.OrganizationLog-module__2S10sxTE-NSzmUPcV85EHF {\n  display: flex;\n  justify-content: space-between;\n}\n\n.OrganizationLog-module__1KFX6x06TKLC3_51lsiMNn {\n  vertical-align: top;\n}\n\n.OrganizationLog-module___X5715SN52__WSoblkg8c {\n  background: #fff7e6;\n}\n\n.OrganizationLog-module__mEsdDzxwvzaZUO1-IiiSY .OrganizationLog-module__m67rllF1xqVTH8XlVYLtJ {\n  background: none;\n}\n\n.OrganizationLog-module__mEsdDzxwvzaZUO1-IiiSY .OrganizationLog-module__1NT5_B47L9oBXf2DGEszWi {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  width: 400px;\n  white-space: nowrap;\n}\n", "",{"version":3,"sources":["OrganizationLog.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,uBAAuB;EACvB,YAAY;EACZ,mBAAmB;AACrB","file":"OrganizationLog.module.css","sourcesContent":[".root {\n  margin-top: 24px;\n}\n\n.header {\n  display: flex;\n  justify-content: space-between;\n}\n\n.align-top {\n  vertical-align: top;\n}\n\n.no-author {\n  background: #fff7e6;\n}\n\n.root .no-background {\n  background: none;\n}\n\n.root .short-description {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  width: 400px;\n  white-space: nowrap;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "OrganizationLog-module__mEsdDzxwvzaZUO1-IiiSY",
	"header": "OrganizationLog-module__2S10sxTE-NSzmUPcV85EHF",
	"align-top": "OrganizationLog-module__1KFX6x06TKLC3_51lsiMNn",
	"no-author": "OrganizationLog-module___X5715SN52__WSoblkg8c",
	"no-background": "OrganizationLog-module__m67rllF1xqVTH8XlVYLtJ",
	"short-description": "OrganizationLog-module__1NT5_B47L9oBXf2DGEszWi"
};
module.exports = exports;
