// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".NotificationPolicy-module__Oavj4aeohiDZuC9-Fp_du {\n  z-index: 1062;\n}\n\n.NotificationPolicy-module__1lDTNQgGqr7vZ_kuDA_-rw {\n  display: flex;\n  align-items: center;\n}\n\n.NotificationPolicy-module__Oavj4aeohiDZuC9-Fp_du .NotificationPolicy-module__21_L5Ss5V-0Xswcuz1ZSGK {\n  margin-right: 10px;\n  flex-shrink: 0;\n}\n\n.NotificationPolicy-module__1lDTNQgGqr7vZ_kuDA_-rw .NotificationPolicy-module__162S5NFcv_sR8eh8Mct20U {\n  width: 250px !important;\n  flex-shrink: 0;\n}\n", "",{"version":3,"sources":["NotificationPolicy.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,uBAAuB;EACvB,cAAc;AAChB","file":"NotificationPolicy.module.css","sourcesContent":[".root {\n  z-index: 1062;\n}\n\n.step {\n  display: flex;\n  align-items: center;\n}\n\n.root .control {\n  margin-right: 10px;\n  flex-shrink: 0;\n}\n\n.step .select {\n  width: 250px !important;\n  flex-shrink: 0;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "NotificationPolicy-module__Oavj4aeohiDZuC9-Fp_du",
	"step": "NotificationPolicy-module__1lDTNQgGqr7vZ_kuDA_-rw",
	"control": "NotificationPolicy-module__21_L5Ss5V-0Xswcuz1ZSGK",
	"select": "NotificationPolicy-module__162S5NFcv_sR8eh8Mct20U"
};
module.exports = exports;
