// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__3LuprPDHB3sRI6JMTxzU8q {\n  margin-bottom: 15px;\n}\n\n.index-module__1y0r2_zZkQtob7kQCKj3A- {\n  font-size: 16px;\n}\n\n.index-module__1Bnjcf7_cVlFD5qHwLT408 {\n  display: block;\n}\n\n.index-module__1WRpIHHSVx7Dj_NtsVwAPl {\n  margin-top: 24px;\n}\n\n.index-module__1A-8FRC_eWCTGFWZIlFScl {\n  margin-top: 8px;\n}\n\n.index-module__2huGmI82hdoI3uXEt8WhpO {\n  display: block;\n  border-radius: 2px;\n  border: var(--border);\n  padding: 4px;\n  background-color: var(--secondary-background);\n  overflow-wrap: break-word;\n}\n\n.index-module__2CSSaN1jydk-WI_t3ykXXM {\n  color: var(--warning-text-color);\n}\n\n.index-module__2OtIY2QwcbqPP3auPbcAmt {\n  color: var(--error-text-color);\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,qBAAqB;EACrB,YAAY;EACZ,6CAA6C;EAC7C,yBAAyB;AAC3B;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,8BAA8B;AAChC","file":"index.module.css","sourcesContent":[".user-item {\n  margin-bottom: 15px;\n}\n\n.user-value {\n  font-size: 16px;\n}\n\n.iCal-settings {\n  display: block;\n}\n\n.iCal-button {\n  margin-top: 24px;\n}\n\n.iCal-link-container {\n  margin-top: 8px;\n}\n\n.iCal-link {\n  display: block;\n  border-radius: 2px;\n  border: var(--border);\n  padding: 4px;\n  background-color: var(--secondary-background);\n  overflow-wrap: break-word;\n}\n\n.warning-icon {\n  color: var(--warning-text-color);\n}\n\n.error-message {\n  color: var(--error-text-color);\n}\n"]}]);
// Exports
exports.locals = {
	"user-item": "index-module__3LuprPDHB3sRI6JMTxzU8q",
	"user-value": "index-module__1y0r2_zZkQtob7kQCKj3A-",
	"iCal-settings": "index-module__1Bnjcf7_cVlFD5qHwLT408",
	"iCal-button": "index-module__1WRpIHHSVx7Dj_NtsVwAPl",
	"iCal-link-container": "index-module__1A-8FRC_eWCTGFWZIlFScl",
	"iCal-link": "index-module__2huGmI82hdoI3uXEt8WhpO",
	"warning-icon": "index-module__2CSSaN1jydk-WI_t3ykXXM",
	"error-message": "index-module__2OtIY2QwcbqPP3auPbcAmt"
};
module.exports = exports;
