// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".UserSettings-module__1rF4PUjiH6Pq5hVcAt1RW2 {\n  width: 720px;\n}\n\n.UserSettings-module__1rF4PUjiH6Pq5hVcAt1RW2.UserSettings-module__5mOdkll7Y0HxyiRb5Jcia {\n  width: 1100px;\n}\n", "",{"version":3,"sources":["UserSettings.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;AACf","file":"UserSettings.module.css","sourcesContent":[".modal {\n  width: 720px;\n}\n\n.modal.modal-wide {\n  width: 1100px;\n}\n"]}]);
// Exports
exports.locals = {
	"modal": "UserSettings-module__1rF4PUjiH6Pq5hVcAt1RW2",
	"modal-wide": "UserSettings-module__5mOdkll7Y0HxyiRb5Jcia"
};
module.exports = exports;
