// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".PluginConfigPage-module__bEQHh75c9pUmogE26zQ69 {\n  width: 100%;\n}\n\n.PluginConfigPage-module__JFbMtn1oseAsta0W-MAlo {\n  margin-bottom: 24px;\n  margin-top: 24px;\n}\n", "",{"version":3,"sources":["PluginConfigPage.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;AAClB","file":"PluginConfigPage.module.css","sourcesContent":[".command-line {\n  width: 100%;\n}\n\n.info-block {\n  margin-bottom: 24px;\n  margin-top: 24px;\n}\n"]}]);
// Exports
exports.locals = {
	"command-line": "PluginConfigPage-module__bEQHh75c9pUmogE26zQ69",
	"info-block": "PluginConfigPage-module__JFbMtn1oseAsta0W-MAlo"
};
module.exports = exports;
