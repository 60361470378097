// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".EscalationChainCard-module__23PM8-xmLTqv2MTNr3lKCr {\n  display: block;\n}\n", "",{"version":3,"sources":["EscalationChainCard.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB","file":"EscalationChainCard.module.css","sourcesContent":[".root {\n  display: block;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "EscalationChainCard-module__23PM8-xmLTqv2MTNr3lKCr"
};
module.exports = exports;
