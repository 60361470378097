// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".VerticalTabsBar-module__1__sqOSb62zilWFaXIk0S3 {\n  display: flex;\n  flex-direction: column;\n}\n\n.VerticalTabsBar-module__oxnaB1zFWvDyBBFGrUM7E {\n  cursor: pointer;\n  position: relative;\n  padding: 12px;\n  opacity: 0.8;\n}\n\n.VerticalTabsBar-module__oxnaB1zFWvDyBBFGrUM7E:hover {\n  background: var(--secondary-background);\n  opacity: 1;\n}\n\n.VerticalTabsBar-module__3lICJLwM3Ex4qPl8KL1oDf {\n  cursor: default;\n  opacity: 1;\n}\n\n.VerticalTabsBar-module__3lICJLwM3Ex4qPl8KL1oDf::before {\n  display: block;\n  content: '';\n  position: absolute;\n  left: 0;\n  top: 12px;\n  bottom: 12px;\n  width: 4px;\n  background-image: linear-gradient(270deg, #f55f3e 0%, #f83 100%);\n}\n", "",{"version":3,"sources":["VerticalTabsBar.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,aAAa;EACb,YAAY;AACd;;AAEA;EACE,uCAAuC;EACvC,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,cAAc;EACd,WAAW;EACX,kBAAkB;EAClB,OAAO;EACP,SAAS;EACT,YAAY;EACZ,UAAU;EACV,gEAAgE;AAClE","file":"VerticalTabsBar.module.css","sourcesContent":[".root {\n  display: flex;\n  flex-direction: column;\n}\n\n.tab {\n  cursor: pointer;\n  position: relative;\n  padding: 12px;\n  opacity: 0.8;\n}\n\n.tab:hover {\n  background: var(--secondary-background);\n  opacity: 1;\n}\n\n.tab_active {\n  cursor: default;\n  opacity: 1;\n}\n\n.tab_active::before {\n  display: block;\n  content: '';\n  position: absolute;\n  left: 0;\n  top: 12px;\n  bottom: 12px;\n  width: 4px;\n  background-image: linear-gradient(270deg, #f55f3e 0%, #f83 100%);\n}\n"]}]);
// Exports
exports.locals = {
	"root": "VerticalTabsBar-module__1__sqOSb62zilWFaXIk0S3",
	"tab": "VerticalTabsBar-module__oxnaB1zFWvDyBBFGrUM7E",
	"tab_active": "VerticalTabsBar-module__3lICJLwM3Ex4qPl8KL1oDf"
};
module.exports = exports;
