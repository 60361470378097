// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__37D2fUzBZ5OoZU2AGx6nzt {\n  display: block;\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB","file":"index.module.css","sourcesContent":[".root {\n  display: block;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "index-module__37D2fUzBZ5OoZU2AGx6nzt"
};
module.exports = exports;
