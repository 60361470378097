// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".PhoneVerification-module__3tSt3yKItl_7SJ77IMWE4j {\n  flex-grow: 1;\n}\n\n.PhoneVerification-module__Ub3n_jS7Emb_st8fCqb35 {\n  font-weight: bolder;\n  padding: 0 4px;\n}\n", "",{"version":3,"sources":["PhoneVerification.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,cAAc;AAChB","file":"PhoneVerification.module.css","sourcesContent":[".input {\n  flex-grow: 1;\n}\n\n.telegram-code {\n  font-weight: bolder;\n  padding: 0 4px;\n}\n"]}]);
// Exports
exports.locals = {
	"input": "PhoneVerification-module__3tSt3yKItl_7SJ77IMWE4j",
	"telegram-code": "PhoneVerification-module__Ub3n_jS7Emb_st8fCqb35"
};
module.exports = exports;
