// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".SlackSettings-module__-IXdLstGRmKlpRA7yr4wu {\n  margin-bottom: 20px;\n}\n\n.SlackSettings-module__1NZtIcq9MVNvqvG3OMDrxR {\n  width: 100%;\n  padding: 50px 0;\n}\n\n.SlackSettings-module__2Vrm_P1CJdIHO05M_et2Vb .SlackSettings-module__qdATx24iWlF1W6SapFOgw {\n  width: 400px;\n}\n\n.SlackSettings-module__HEb7a1NL9iM7pIDUCkVfN {\n  margin-bottom: 20px;\n  border-bottom: 1px solid rgba(204, 204, 220, 0.25);\n}\n", "",{"version":3,"sources":["SlackSettings.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,kDAAkD;AACpD","file":"SlackSettings.module.css","sourcesContent":[".title {\n  margin-bottom: 20px;\n}\n\n.stub {\n  width: 100%;\n  padding: 50px 0;\n}\n\n.root .select {\n  width: 400px;\n}\n\n.slack-settings {\n  margin-bottom: 20px;\n  border-bottom: 1px solid rgba(204, 204, 220, 0.25);\n}\n"]}]);
// Exports
exports.locals = {
	"title": "SlackSettings-module__-IXdLstGRmKlpRA7yr4wu",
	"stub": "SlackSettings-module__1NZtIcq9MVNvqvG3OMDrxR",
	"root": "SlackSettings-module__2Vrm_P1CJdIHO05M_et2Vb",
	"select": "SlackSettings-module__qdATx24iWlF1W6SapFOgw",
	"slack-settings": "SlackSettings-module__HEb7a1NL9iM7pIDUCkVfN"
};
module.exports = exports;
