// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".PersonalNotificationSettings-module__3TDtOvIFHjQF18Ej4WrRA8 {\n  margin-bottom: 25px;\n}\n\n.PersonalNotificationSettings-module__3TDtOvIFHjQF18Ej4WrRA8 .PersonalNotificationSettings-module__2mjwNcQCM1II5Mlfkd6D-A {\n  margin: 15px 0 0 15px;\n}\n\n.PersonalNotificationSettings-module__x96CnlwaHvvY9y1DkPgtS {\n  z-index: 1062;\n}\n\n.PersonalNotificationSettings-module__3TDtOvIFHjQF18Ej4WrRA8 .PersonalNotificationSettings-module__3xRh7J-6_gFbiRCEz5hZY4 {\n  display: flex;\n  align-items: center;\n  margin-left: 10px;\n}\n", "",{"version":3,"sources":["PersonalNotificationSettings.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;AACnB","file":"PersonalNotificationSettings.module.css","sourcesContent":[".root {\n  margin-bottom: 25px;\n}\n\n.root .steps {\n  margin: 15px 0 0 15px;\n}\n\n.sortable-helper {\n  z-index: 1062;\n}\n\n.root .step {\n  display: flex;\n  align-items: center;\n  margin-left: 10px;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "PersonalNotificationSettings-module__3TDtOvIFHjQF18Ej4WrRA8",
	"steps": "PersonalNotificationSettings-module__2mjwNcQCM1II5Mlfkd6D-A",
	"sortable-helper": "PersonalNotificationSettings-module__x96CnlwaHvvY9y1DkPgtS",
	"step": "PersonalNotificationSettings-module__3xRh7J-6_gFbiRCEz5hZY4"
};
module.exports = exports;
