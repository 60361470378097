// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".OrganizationLogFilters-module__22MyeVrS30o5nTbG9Zv9Pp {\n  display: block;\n}\n\n.OrganizationLogFilters-module__22MyeVrS30o5nTbG9Zv9Pp > * {\n  margin-bottom: 10px !important;\n}\n\n.OrganizationLogFilters-module__22MyeVrS30o5nTbG9Zv9Pp > *:not(:last-child) {\n  margin-right: 10px !important;\n}\n\n.OrganizationLogFilters-module__22MyeVrS30o5nTbG9Zv9Pp .OrganizationLogFilters-module__2dYkcisfBE2-XHxfrunMOP {\n  width: 400px;\n}\n\n.OrganizationLogFilters-module__28pxBsGuZeu1ZcgOIQk052 {\n  min-width: 300px;\n}\n", "",{"version":3,"sources":["OrganizationLogFilters.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB","file":"OrganizationLogFilters.module.css","sourcesContent":[".root {\n  display: block;\n}\n\n.root > * {\n  margin-bottom: 10px !important;\n}\n\n.root > *:not(:last-child) {\n  margin-right: 10px !important;\n}\n\n.root .search {\n  width: 400px;\n}\n\n.select {\n  min-width: 300px;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "OrganizationLogFilters-module__22MyeVrS30o5nTbG9Zv9Pp",
	"search": "OrganizationLogFilters-module__2dYkcisfBE2-XHxfrunMOP",
	"select": "OrganizationLogFilters-module__28pxBsGuZeu1ZcgOIQk052"
};
module.exports = exports;
