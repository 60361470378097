// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".ChatOps-module__3hlKDRkb2FIO2H40_7c84I {\n  margin-top: 24px;\n  display: flex;\n}\n\n.ChatOps-module__lte7upnVu9ZT9VVcs6zdP {\n  width: 250px;\n}\n\n.ChatOps-module__3kj2BC2H-AG1kcyrPL-hNl {\n  flex-grow: 1;\n  padding-left: 20px;\n  border-left: var(--border);\n}\n", "",{"version":3,"sources":["ChatOps.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,0BAA0B;AAC5B","file":"ChatOps.module.css","sourcesContent":[".root {\n  margin-top: 24px;\n  display: flex;\n}\n\n.tabs {\n  width: 250px;\n}\n\n.content {\n  flex-grow: 1;\n  padding-left: 20px;\n  border-left: var(--border);\n}\n"]}]);
// Exports
exports.locals = {
	"root": "ChatOps-module__3hlKDRkb2FIO2H40_7c84I",
	"tabs": "ChatOps-module__lte7upnVu9ZT9VVcs6zdP",
	"content": "ChatOps-module__3kj2BC2H-AG1kcyrPL-hNl"
};
module.exports = exports;
