// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".PluginLink-module__2WEQcTRM9iCdbw7pJcE7JV {\n  color: var(--primary-text-link);\n}\n\n.PluginLink-module__1TrPxRa7B6j61XYoJ9DT6Q {\n  color: var(--disabled-text-color);\n}\n\n.PluginLink-module__2wtDiRpfy_La8I-IyTPCyB {\n  white-space: nowrap;\n}\n", "",{"version":3,"sources":["PluginLink.module.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;AACjC;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,mBAAmB;AACrB","file":"PluginLink.module.css","sourcesContent":[".root {\n  color: var(--primary-text-link);\n}\n\n.root_disabled {\n  color: var(--disabled-text-color);\n}\n\n.no-wrap {\n  white-space: nowrap;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "PluginLink-module__2WEQcTRM9iCdbw7pJcE7JV",
	"root_disabled": "PluginLink-module__1TrPxRa7B6j61XYoJ9DT6Q",
	"no-wrap": "PluginLink-module__2wtDiRpfy_La8I-IyTPCyB"
};
module.exports = exports;
