// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".EscalationChainForm-module__yPDisi9-eqeWeb4Wvg9m- {\n  display: block;\n}\n", "",{"version":3,"sources":["EscalationChainForm.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB","file":"EscalationChainForm.module.css","sourcesContent":[".root {\n  display: block;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "EscalationChainForm-module__yPDisi9-eqeWeb4Wvg9m-"
};
module.exports = exports;
