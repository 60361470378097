export const logoCoors: { [key: string]: { x: number; y: number } } = {
  Grafana: { x: 9, y: 0 },
  'Grafana Alerting': { x: 9, y: 0 },
  Webhook: { x: 2, y: 14 },
  AlertManager: { x: 12, y: 4 },
  Kapacitor: { x: 10, y: 1 },
  Fabric: { x: 8, y: 7 },
  NewRelic: { x: 0, y: 11 },
  DataDog: { x: 3, y: 7 },
  PagerDuty: { x: 0, y: 0 },
  Pingdom: { x: 4, y: 0 },
  ElastAlert: { x: 0, y: 0 },
  'Amazon SNS': { x: 0, y: 2 },
  Curler: { x: 0, y: 0 },
  'Sentry Webhook (Onprem)': { x: 11, y: 12 },
  'Formatted Webhook': { x: 2, y: 14 },
  'HeartBeat Monitoring': { x: 2, y: 14 },
  Stackdriver: { x: 8, y: 8 },
  UptimeRobot: { x: 14, y: 8 },
  Zabbix: { x: 7, y: 14 },
  PRTG: { x: 12, y: 5 },
};
