// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".OutgoingWebhookForm-module__16fKs7-X1opVmr1Ul-6ImI {\n  display: block;\n}\n\n.OutgoingWebhookForm-module__2am25tWSDgJQqq4tePHB0a {\n  margin: 16px 0 0 16px;\n}\n\n.OutgoingWebhookForm-module__3Fa60PPHlEj19XeFBLyU9Y {\n  margin: 4px;\n}\n", "",{"version":3,"sources":["OutgoingWebhookForm.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,WAAW;AACb","file":"OutgoingWebhookForm.module.css","sourcesContent":[".root {\n  display: block;\n}\n\n.title {\n  margin: 16px 0 0 16px;\n}\n\n.content {\n  margin: 4px;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "OutgoingWebhookForm-module__16fKs7-X1opVmr1Ul-6ImI",
	"title": "OutgoingWebhookForm-module__2am25tWSDgJQqq4tePHB0a",
	"content": "OutgoingWebhookForm-module__3Fa60PPHlEj19XeFBLyU9Y"
};
module.exports = exports;
