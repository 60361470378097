// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__3bu6Bq2oN8MljwJgD2gQVT {\n  display: flex;\n  flex-direction: column;\n}\n\n.index-module__2-DmlGh4EYuwuc1A8QFyPY {\n  margin-top: 20px;\n}\n\n.index-module__2QnBSqC7NjFwGsYoEqUV8V {\n  display: flex;\n  flex-direction: row;\n}\n\n.index-module__t3uRlgG2uB7JTRkDixyle {\n  display: flex;\n  flex-direction: column;\n}\n\n.index-module__1M3md8KGT7a5g6JgQAf2xk {\n  margin-left: 24px;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n}\n\n.index-module__363Ch5FTol8GESclgKViqR {\n  text-align: center;\n  justify-content: center;\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;EAClB,uBAAuB;AACzB","file":"index.module.css","sourcesContent":[".root {\n  display: flex;\n  flex-direction: column;\n}\n\n.content {\n  margin-top: 20px;\n}\n\n.columns {\n  display: flex;\n  flex-direction: row;\n}\n\n.col {\n  display: flex;\n  flex-direction: column;\n}\n\n.right {\n  margin-left: 24px;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n}\n\n.right_centered {\n  text-align: center;\n  justify-content: center;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "index-module__3bu6Bq2oN8MljwJgD2gQVT",
	"content": "index-module__2-DmlGh4EYuwuc1A8QFyPY",
	"columns": "index-module__2QnBSqC7NjFwGsYoEqUV8V",
	"col": "index-module__t3uRlgG2uB7JTRkDixyle",
	"right": "index-module__1M3md8KGT7a5g6JgQAf2xk",
	"right_centered": "index-module__363Ch5FTol8GESclgKViqR"
};
module.exports = exports;
