// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".AlertReceiveChannelCard-module__2YH1Yi3MfsFMiVlP7IQu6 {\n  display: block;\n}\n\n.AlertReceiveChannelCard-module__b0yTQtGzgtWwVkYi6mXTH {\n  width: 16px;\n  height: 16px;\n}\n\n.AlertReceiveChannelCard-module__1lgUyuia6xrbzTHgcRm20F {\n  cursor: pointer;\n}\n\n.AlertReceiveChannelCard-module__uoteNeDGsxaThKwET4snv {\n  font-size: 12px;\n}\n", "",{"version":3,"sources":["AlertReceiveChannelCard.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB","file":"AlertReceiveChannelCard.module.css","sourcesContent":[".root {\n  display: block;\n}\n\n.heartbeat {\n  width: 16px;\n  height: 16px;\n}\n\n.heartbeat-icon {\n  cursor: pointer;\n}\n\n.alertsInfoText {\n  font-size: 12px;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "AlertReceiveChannelCard-module__2YH1Yi3MfsFMiVlP7IQu6",
	"heartbeat": "AlertReceiveChannelCard-module__b0yTQtGzgtWwVkYi6mXTH",
	"heartbeat-icon": "AlertReceiveChannelCard-module__1lgUyuia6xrbzTHgcRm20F",
	"alertsInfoText": "AlertReceiveChannelCard-module__uoteNeDGsxaThKwET4snv"
};
module.exports = exports;
