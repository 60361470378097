// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".TemplatePreview-module__2ExVogkTiwcmoavPqa2KvL {\n  width: 100%;\n}\n\n.TemplatePreview-module__1i7yl8ypoOs0APJalZMhbe ul {\n  margin-left: 24px;\n}\n\n.TemplatePreview-module__1i7yl8ypoOs0APJalZMhbe p {\n  margin-bottom: 0;\n}\n\n.TemplatePreview-module__1i7yl8ypoOs0APJalZMhbe code {\n  white-space: break-spaces;\n}\n", "",{"version":3,"sources":["TemplatePreview.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B","file":"TemplatePreview.module.css","sourcesContent":[".img {\n  width: 100%;\n}\n\n.message ul {\n  margin-left: 24px;\n}\n\n.message p {\n  margin-bottom: 0;\n}\n\n.message code {\n  white-space: break-spaces;\n}\n"]}]);
// Exports
exports.locals = {
	"img": "TemplatePreview-module__2ExVogkTiwcmoavPqa2KvL",
	"message": "TemplatePreview-module__1i7yl8ypoOs0APJalZMhbe"
};
module.exports = exports;
