// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Test-module__10K6FOUxSbr0fHaRwyPeSA {\n  margin-top: 24px;\n}\n\n.Test-module__4MpVJwQ2vdiAJnm7WNbeE {\n  width: 400px;\n}\n", "",{"version":3,"sources":["Test.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd","file":"Test.module.css","sourcesContent":[".root {\n  margin-top: 24px;\n}\n\n.select {\n  width: 400px;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "Test-module__10K6FOUxSbr0fHaRwyPeSA",
	"select": "Test-module__4MpVJwQ2vdiAJnm7WNbeE"
};
module.exports = exports;
