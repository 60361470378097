// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".GTable-module__2bPmi-yocbnsX1be5HRF7B table {\n  width: 100%;\n}\n\n.GTable-module__3iSR9t_NSfKMfDuJI5S0LX {\n  margin-top: 20px;\n}\n\n.GTable-module__rZwFLPEpO0ehnn5FS0vuD {\n  display: inline-flex;\n}\n", "",{"version":3,"sources":["GTable.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,oBAAoB;AACtB","file":"GTable.module.css","sourcesContent":[".root table {\n  width: 100%;\n}\n\n.pagination {\n  margin-top: 20px;\n}\n\n.checkbox {\n  display: inline-flex;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "GTable-module__2bPmi-yocbnsX1be5HRF7B",
	"pagination": "GTable-module__3iSR9t_NSfKMfDuJI5S0LX",
	"checkbox": "GTable-module__rZwFLPEpO0ehnn5FS0vuD"
};
module.exports = exports;
