// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Block-module__1AaOFLXIZaCyhUfUsvSSf7 {\n  padding: 16px;\n  border-radius: 2px;\n}\n\n.theme-dark .Block-module__1DEze3BV5NfjDLGS2ZjuyC {\n  border: var(--border);\n}\n\n.theme-light .Block-module__1DEze3BV5NfjDLGS2ZjuyC {\n  border: var(--border);\n}\n\n.theme-dark .Block-module__3qP5O0UAmWtRYZo4JQKhza {\n  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.6);\n}\n\n.Block-module__3hGjXFvCfymOBNmzMEUdr0 {\n  background: var(--secondary-background);\n}\n", "",{"version":3,"sources":["Block.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,uCAAuC;AACzC","file":"Block.module.css","sourcesContent":[".root {\n  padding: 16px;\n  border-radius: 2px;\n}\n\n:global(.theme-dark) .root_bordered {\n  border: var(--border);\n}\n\n:global(.theme-light) .root_bordered {\n  border: var(--border);\n}\n\n:global(.theme-dark) .root_shadowed {\n  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.6);\n}\n\n.root_with-background {\n  background: var(--secondary-background);\n}\n"]}]);
// Exports
exports.locals = {
	"root": "Block-module__1AaOFLXIZaCyhUfUsvSSf7",
	"root_bordered": "Block-module__1DEze3BV5NfjDLGS2ZjuyC",
	"root_shadowed": "Block-module__3qP5O0UAmWtRYZo4JQKhza",
	"root_with-background": "Block-module__3hGjXFvCfymOBNmzMEUdr0"
};
module.exports = exports;
