// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".MobileAppVerification-module__2tdbzEzD72gYwf4W4N5zc1 {\n  flex-grow: 1;\n}\n\n.MobileAppVerification-module__2oGVtx_Z35IJa1TwVrHKhk {\n  font-weight: bolder;\n  padding: 0 4px;\n}\n", "",{"version":3,"sources":["MobileAppVerification.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,cAAc;AAChB","file":"MobileAppVerification.module.css","sourcesContent":[".input {\n  flex-grow: 1;\n}\n\n.telegram-code {\n  font-weight: bolder;\n  padding: 0 4px;\n}\n"]}]);
// Exports
exports.locals = {
	"input": "MobileAppVerification-module__2tdbzEzD72gYwf4W4N5zc1",
	"telegram-code": "MobileAppVerification-module__2oGVtx_Z35IJa1TwVrHKhk"
};
module.exports = exports;
