// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".UserTooltip-module__2rYtXW05egcCv5dVdWUh3Q {\n  display: block;\n}\n", "",{"version":3,"sources":["UserTooltip.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB","file":"UserTooltip.module.css","sourcesContent":[".root {\n  display: block;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "UserTooltip-module__2rYtXW05egcCv5dVdWUh3Q"
};
module.exports = exports;
