// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".GSelect-module__3gD4XR2L6Wlgxgth7SHgwX {\n  min-width: 180px;\n}\n", "",{"version":3,"sources":["GSelect.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB","file":"GSelect.module.css","sourcesContent":[".root {\n  min-width: 180px;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "GSelect-module__3gD4XR2L6Wlgxgth7SHgwX"
};
module.exports = exports;
