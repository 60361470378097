// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".TelegramInfo-module__2hx_oUqWW9oCyik7rmXKQa {\n  color: var(--primary-text-link);\n}\n\n.TelegramInfo-module__3M3r6uRUzrLeCaMNutfxB0 {\n  display: flex;\n  justify-content: space-between;\n}\n", "",{"version":3,"sources":["TelegramInfo.module.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC","file":"TelegramInfo.module.css","sourcesContent":[".verification-code {\n  color: var(--primary-text-link);\n}\n\n.verification-code-text {\n  display: flex;\n  justify-content: space-between;\n}\n"]}]);
// Exports
exports.locals = {
	"verification-code": "TelegramInfo-module__2hx_oUqWW9oCyik7rmXKQa",
	"verification-code-text": "TelegramInfo-module__3M3r6uRUzrLeCaMNutfxB0"
};
module.exports = exports;
