// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".MigrationTool-module__tg9A9WagHfw4beYK1jJ9q {\n  margin-top: 24px;\n  width: 1000px;\n}\n\n.MigrationTool-module__tg9A9WagHfw4beYK1jJ9q ul,\n.MigrationTool-module__tg9A9WagHfw4beYK1jJ9q ol {\n  padding-left: 20px;\n}\n", "",{"version":3,"sources":["MigrationTool.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;AACf;;AAEA;;EAEE,kBAAkB;AACpB","file":"MigrationTool.module.css","sourcesContent":[".root {\n  margin-top: 24px;\n  width: 1000px;\n}\n\n.root ul,\n.root ol {\n  padding-left: 20px;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "MigrationTool-module__tg9A9WagHfw4beYK1jJ9q"
};
module.exports = exports;
