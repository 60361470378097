// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".ScheduleForm-module__1a8pJVoV7fOjpTXEfJS1t8 {\n  display: block;\n}\n\n.ScheduleForm-module__2222sh3zoaKnc5aUhQFu0j {\n  margin: 16px 0 0 16px;\n}\n\n.ScheduleForm-module__1IIef5cDiol9BIkX9QuDLG {\n  margin: 4px 4px 50px 4px;\n}\n", "",{"version":3,"sources":["ScheduleForm.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,wBAAwB;AAC1B","file":"ScheduleForm.module.css","sourcesContent":[".root {\n  display: block;\n}\n\n.title {\n  margin: 16px 0 0 16px;\n}\n\n.content {\n  margin: 4px 4px 50px 4px;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "ScheduleForm-module__1a8pJVoV7fOjpTXEfJS1t8",
	"title": "ScheduleForm-module__2222sh3zoaKnc5aUhQFu0j",
	"content": "ScheduleForm-module__1IIef5cDiol9BIkX9QuDLG"
};
module.exports = exports;
