// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".TelegramIntegrationButton-module__2X8iw3p-eyWFL7F9v12lf2 {\n  color: var(--primary-text-link);\n}\n\n.TelegramIntegrationButton-module__2UWrshU--EdQJZWKxndWE8 {\n  display: block;\n  text-align: left;\n  margin-bottom: 12px;\n}\n\n.TelegramIntegrationButton-module__3MGnbLAfZp0xUWun4vQZx0 {\n  text-decoration: underline;\n}\n\n.TelegramIntegrationButton-module__2Rf-6rvuDxPymkVzdUj7Z9 {\n  color: var(--primary-text-link);\n}\n\n.TelegramIntegrationButton-module__1iD3wkuFp2GhtuvdIqZB28 {\n  margin-top: 24px;\n}\n", "",{"version":3,"sources":["TelegramIntegrationButton.module.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;AACjC;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,gBAAgB;AAClB","file":"TelegramIntegrationButton.module.css","sourcesContent":[".telegram-bot {\n  color: var(--primary-text-link);\n}\n\n.telegram-instruction-container {\n  display: block;\n  text-align: left;\n  margin-bottom: 12px;\n}\n\n.verification-code {\n  text-decoration: underline;\n}\n\n.copy-icon {\n  color: var(--primary-text-link);\n}\n\n.telegram-instruction-cancel {\n  margin-top: 24px;\n}\n"]}]);
// Exports
exports.locals = {
	"telegram-bot": "TelegramIntegrationButton-module__2X8iw3p-eyWFL7F9v12lf2",
	"telegram-instruction-container": "TelegramIntegrationButton-module__2UWrshU--EdQJZWKxndWE8",
	"verification-code": "TelegramIntegrationButton-module__3MGnbLAfZp0xUWun4vQZx0",
	"copy-icon": "TelegramIntegrationButton-module__2Rf-6rvuDxPymkVzdUj7Z9",
	"telegram-instruction-cancel": "TelegramIntegrationButton-module__1iD3wkuFp2GhtuvdIqZB28"
};
module.exports = exports;
