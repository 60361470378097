// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Collapse-module__25AaMH8UiuSo571WH8QQkw {\n  border: var(--border);\n}\n\n.Collapse-module__283tKpzRLFhJn1j4EdYwCu {\n  padding: 8px;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n}\n\n.Collapse-module__1ia-Ih3Z-9RxvIraODqkiD {\n  background: var(--secondary-background);\n}\n\n.Collapse-module__QBdQ91Zz6dnbMEdCEssFf {\n  display: block;\n  margin-left: 8px;\n  flex-grow: 1;\n}\n\n.Collapse-module__RDhsOciUkvFH8RyJo5wuN {\n  padding: 16px;\n}\n\n.Collapse-module__2Ry660eobYhK-kTEcI3v-t {\n  color: var(--secondary-text-color);\n}\n", "",{"version":3,"sources":["Collapse.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kCAAkC;AACpC","file":"Collapse.module.css","sourcesContent":[".root {\n  border: var(--border);\n}\n\n.header {\n  padding: 8px;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n}\n\n.header_with-background {\n  background: var(--secondary-background);\n}\n\n.label {\n  display: block;\n  margin-left: 8px;\n  flex-grow: 1;\n}\n\n.content {\n  padding: 16px;\n}\n\n.icon {\n  color: var(--secondary-text-color);\n}\n"]}]);
// Exports
exports.locals = {
	"root": "Collapse-module__25AaMH8UiuSo571WH8QQkw",
	"header": "Collapse-module__283tKpzRLFhJn1j4EdYwCu",
	"header_with-background": "Collapse-module__1ia-Ih3Z-9RxvIraODqkiD",
	"label": "Collapse-module__QBdQ91Zz6dnbMEdCEssFf",
	"content": "Collapse-module__RDhsOciUkvFH8RyJo5wuN",
	"icon": "Collapse-module__2Ry660eobYhK-kTEcI3v-t"
};
module.exports = exports;
