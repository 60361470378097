// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".TelegramSettings-module__26MR0xc3l_dLjk36V6UgSH {\n  display: block;\n}\n\n.TelegramSettings-module__3ej_zgL39BVoB8asiWyCw_ {\n  display: flex;\n  justify-content: space-between;\n}\n", "",{"version":3,"sources":["TelegramSettings.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC","file":"TelegramSettings.module.css","sourcesContent":[".root {\n  display: block;\n}\n\n.header {\n  display: flex;\n  justify-content: space-between;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "TelegramSettings-module__26MR0xc3l_dLjk36V6UgSH",
	"header": "TelegramSettings-module__3ej_zgL39BVoB8asiWyCw_"
};
module.exports = exports;
