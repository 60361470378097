// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".IncidentMatcher-module__2rAUHaqOKFgW2i0VaTxMkm {\n  display: block;\n}\n\n.IncidentMatcher-module__1eRMsqDKGHFkYMpuJDNhNt {\n  display: flex;\n  gap: 10px;\n  align-items: flex-start;\n  width: 100%;\n}\n\n.IncidentMatcher-module__1eRMsqDKGHFkYMpuJDNhNt > div {\n  width: 50%;\n}\n\n.IncidentMatcher-module__S7v5cvYErMZ-P6T_N84Kd {\n  list-style-type: none;\n}\n\n.IncidentMatcher-module__2Xd1qIavVsCmHi_7hDFGeO {\n  margin-bottom: 10px;\n}\n", "",{"version":3,"sources":["IncidentMatcher.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;AACrB","file":"IncidentMatcher.module.css","sourcesContent":[".root {\n  display: block;\n}\n\n.columns {\n  display: flex;\n  gap: 10px;\n  align-items: flex-start;\n  width: 100%;\n}\n\n.columns > div {\n  width: 50%;\n}\n\n.incident-item-list {\n  list-style-type: none;\n}\n\n.title {\n  margin-bottom: 10px;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "IncidentMatcher-module__2rAUHaqOKFgW2i0VaTxMkm",
	"columns": "IncidentMatcher-module__1eRMsqDKGHFkYMpuJDNhNt",
	"incident-item-list": "IncidentMatcher-module__S7v5cvYErMZ-P6T_N84Kd",
	"title": "IncidentMatcher-module__2Xd1qIavVsCmHi_7hDFGeO"
};
module.exports = exports;
