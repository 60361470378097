// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".OutgoingWebhooks-module__39sl6I1qa5nhnvBLUyAnMW {\n  margin-top: 24px;\n}\n\n.OutgoingWebhooks-module__2jAO59uA6H0qhZ0FT5fEn5 {\n  display: flex;\n  justify-content: space-between;\n}\n", "",{"version":3,"sources":["OutgoingWebhooks.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC","file":"OutgoingWebhooks.module.css","sourcesContent":[".root {\n  margin-top: 24px;\n}\n\n.header {\n  display: flex;\n  justify-content: space-between;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "OutgoingWebhooks-module__39sl6I1qa5nhnvBLUyAnMW",
	"header": "OutgoingWebhooks-module__2jAO59uA6H0qhZ0FT5fEn5"
};
module.exports = exports;
