// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".AttachIncidentForm-module__3PpHqBFEtsZ-12_3h_n-tl {\n  display: block;\n}\n", "",{"version":3,"sources":["AttachIncidentForm.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB","file":"AttachIncidentForm.module.css","sourcesContent":[".root {\n  display: block;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "AttachIncidentForm-module__3PpHqBFEtsZ-12_3h_n-tl"
};
module.exports = exports;
