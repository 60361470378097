// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Avatar-module__3t_AbbaZvdJ8uA99r02Y5W {\n  display: inline-block;\n  border-radius: 50%;\n  overflow: hidden;\n}\n\n.Avatar-module__2BwSINmvfgQxfeV5_Dt6Jm {\n  width: 16px;\n  height: 16px;\n}\n\n.Avatar-module__24bUPrpMpcp11PLdUik9v- {\n  width: 24px;\n  height: 24px;\n}\n\n.Avatar-module__3js16w2FjZza41_RqEeq79 {\n  width: 32px;\n  height: 32px;\n}\n", "",{"version":3,"sources":["Avatar.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd","file":"Avatar.module.css","sourcesContent":[".root {\n  display: inline-block;\n  border-radius: 50%;\n  overflow: hidden;\n}\n\n.avatarSize-small {\n  width: 16px;\n  height: 16px;\n}\n\n.avatarSize-big {\n  width: 24px;\n  height: 24px;\n}\n\n.avatarSize-large {\n  width: 32px;\n  height: 32px;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "Avatar-module__3t_AbbaZvdJ8uA99r02Y5W",
	"avatarSize-small": "Avatar-module__2BwSINmvfgQxfeV5_Dt6Jm",
	"avatarSize-big": "Avatar-module__24bUPrpMpcp11PLdUik9v-",
	"avatarSize-large": "Avatar-module__3js16w2FjZza41_RqEeq79"
};
module.exports = exports;
