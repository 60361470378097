// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__1cirgV40NhHJXsxsbNIEj6 {\n  border-radius: 2px;\n  background: var(--secondary-background);\n  padding: 2px 2px 2px 12px;\n  flex-wrap: wrap;\n  width: 100%;\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,uCAAuC;EACvC,yBAAyB;EACzB,eAAe;EACf,WAAW;AACb","file":"index.module.css","sourcesContent":[".root {\n  border-radius: 2px;\n  background: var(--secondary-background);\n  padding: 2px 2px 2px 12px;\n  flex-wrap: wrap;\n  width: 100%;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "index-module__1cirgV40NhHJXsxsbNIEj6"
};
module.exports = exports;
