// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".LiveSettings-module__1kGYkhlLaHORhcH-YcvF2U {\n  margin-top: 24px;\n}\n\n.LiveSettings-module__3gdy-PYWbWb5NcjhGU2_XM {\n  vertical-align: top;\n}\n\n.LiveSettings-module__PhttPEFTfDdHJPavngM8y {\n  display: flex;\n  justify-content: space-between;\n}\n\n.LiveSettings-module__15VXl8KtZv6PhsLHm0lvep {\n  /* vertical-align: top; */\n}\n\n.LiveSettings-module__3h4LZCboIvQHgI03v69VBT {\n  color: green;\n}\n\n.LiveSettings-module__ZNohKarjABDPuwF-cBrtm > a {\n  color: var(--primary-text-link);\n}\n\n.LiveSettings-module__ZNohKarjABDPuwF-cBrtm {\n  word-wrap: break-word;\n  word-break: break-word;\n}\n", "",{"version":3,"sources":["LiveSettings.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,qBAAqB;EACrB,sBAAsB;AACxB","file":"LiveSettings.module.css","sourcesContent":[".root {\n  margin-top: 24px;\n}\n\n.align-top {\n  vertical-align: top;\n}\n\n.header {\n  display: flex;\n  justify-content: space-between;\n}\n\n.row {\n  /* vertical-align: top; */\n}\n\n.check-icon {\n  color: green;\n}\n\n.description-style > a {\n  color: var(--primary-text-link);\n}\n\n.description-style {\n  word-wrap: break-word;\n  word-break: break-word;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "LiveSettings-module__1kGYkhlLaHORhcH-YcvF2U",
	"align-top": "LiveSettings-module__3gdy-PYWbWb5NcjhGU2_XM",
	"header": "LiveSettings-module__PhttPEFTfDdHJPavngM8y",
	"row": "LiveSettings-module__15VXl8KtZv6PhsLHm0lvep",
	"check-icon": "LiveSettings-module__3h4LZCboIvQHgI03v69VBT",
	"description-style": "LiveSettings-module__ZNohKarjABDPuwF-cBrtm"
};
module.exports = exports;
