// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".WithPermissionControl-module__29rXyp1itxgriw7Yl548mz {\n  display: inline;\n}\n", "",{"version":3,"sources":["WithPermissionControl.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB","file":"WithPermissionControl.module.css","sourcesContent":[".wrapper {\n  display: inline;\n}\n"]}]);
// Exports
exports.locals = {
	"wrapper": "WithPermissionControl-module__29rXyp1itxgriw7Yl548mz"
};
module.exports = exports;
