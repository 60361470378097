// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".ApiTokenSettings-module__1IPaIikmJ4xUOFBLAIpTO7 {\n  margin: 20px 0;\n}\n\n.ApiTokenSettings-module__1ci_K_XztJVE5qnXGhBFM8 {\n  margin: 20px 0;\n}\n\n.ApiTokenSettings-module__15R3JqFDHNK3WwdfCFQcds {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n", "",{"version":3,"sources":["ApiTokenSettings.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB","file":"ApiTokenSettings.module.css","sourcesContent":[".form {\n  margin: 20px 0;\n}\n\n.incident-matcher {\n  margin: 20px 0;\n}\n\n.header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n"]}]);
// Exports
exports.locals = {
	"form": "ApiTokenSettings-module__1IPaIikmJ4xUOFBLAIpTO7",
	"incident-matcher": "ApiTokenSettings-module__1ci_K_XztJVE5qnXGhBFM8",
	"header": "ApiTokenSettings-module__15R3JqFDHNK3WwdfCFQcds"
};
module.exports = exports;
