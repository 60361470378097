// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/integration-logos.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/img/PagerDuty.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/img/ElastAlert.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../assets/img/HeartBeatMonitoring.png");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../assets/img/grafana_icon.svg");
exports = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
exports.push([module.id, ".IntegrationLogo-module__3kWG_wMRmaFm3qVwx5LHG9 {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n}\n\n.IntegrationLogo-module__DeZpX8NQ9F_rqVQn5cTP5 {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-size: 100% !important;\n}\n\n.IntegrationLogo-module__13OyVswzc9QRWah8aCdfj9 {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n  background-size: 100% !important;\n}\n\n.IntegrationLogo-module__38wkfn69w7xR19hM2U-7iM {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n  background-size: 100% !important;\n}\n\n.IntegrationLogo-module__2sSzhyAct5vTPh0IIzWiEQ,\n.IntegrationLogo-module__LrYeHtljZfd_j1T4DSGa3 {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");\n  background-size: 100% !important;\n}\n", "",{"version":3,"sources":["IntegrationLogo.module.css"],"names":[],"mappings":"AAAA;EACE,mDAAuD;EACvD,4BAA4B;AAC9B;;AAEA;EACE,mDAA+C;EAC/C,gCAAgC;AAClC;;AAEA;EACE,mDAAgD;EAChD,gCAAgC;AAClC;;AAEA;EACE,mDAAyD;EACzD,gCAAgC;AAClC;;AAEA;;EAEE,mDAAkD;EAClD,gCAAgC;AAClC","file":"IntegrationLogo.module.css","sourcesContent":[".bg {\n  background: url(../../assets/img/integration-logos.png);\n  background-repeat: no-repeat;\n}\n\n.bg_PagerDuty {\n  background: url(../../assets/img/PagerDuty.png);\n  background-size: 100% !important;\n}\n\n.bg_ElastAlert {\n  background: url(../../assets/img/ElastAlert.svg);\n  background-size: 100% !important;\n}\n\n.bg_HeartBeatMonitoring {\n  background: url(../../assets/img/HeartBeatMonitoring.png);\n  background-size: 100% !important;\n}\n\n.bg_Grafana,\n.bg_GrafanaAlerting {\n  background: url(../../assets/img/grafana_icon.svg);\n  background-size: 100% !important;\n}\n"]}]);
// Exports
exports.locals = {
	"bg": "IntegrationLogo-module__3kWG_wMRmaFm3qVwx5LHG9",
	"bg_PagerDuty": "IntegrationLogo-module__DeZpX8NQ9F_rqVQn5cTP5",
	"bg_ElastAlert": "IntegrationLogo-module__13OyVswzc9QRWah8aCdfj9",
	"bg_HeartBeatMonitoring": "IntegrationLogo-module__38wkfn69w7xR19hM2U-7iM",
	"bg_Grafana": "IntegrationLogo-module__2sSzhyAct5vTPh0IIzWiEQ",
	"bg_GrafanaAlerting": "IntegrationLogo-module__LrYeHtljZfd_j1T4DSGa3"
};
module.exports = exports;
