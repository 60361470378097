// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".UsersFilters-module__UpIvFNtWILvfyTUC8bS0J {\n  display: inline-flex;\n  align-items: center;\n}\n\n.UsersFilters-module__UpIvFNtWILvfyTUC8bS0J .UsersFilters-module__1izu8i9D9hvH6oPY8Yq-Yo {\n  width: 300px;\n}\n\n.UsersFilters-module__UpIvFNtWILvfyTUC8bS0J .UsersFilters-module__1izu8i9D9hvH6oPY8Yq-Yo:focus {\n  width: 500px;\n}\n\n.UsersFilters-module__3gntmhWisf5awBpP3t5Yp2:not(:last-child) {\n  margin-right: 20px;\n}\n", "",{"version":3,"sources":["UsersFilters.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;AACpB","file":"UsersFilters.module.css","sourcesContent":[".root {\n  display: inline-flex;\n  align-items: center;\n}\n\n.root .search {\n  width: 300px;\n}\n\n.root .search:focus {\n  width: 500px;\n}\n\n.control:not(:last-child) {\n  margin-right: 20px;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "UsersFilters-module__UpIvFNtWILvfyTUC8bS0J",
	"search": "UsersFilters-module__1izu8i9D9hvH6oPY8Yq-Yo",
	"control": "UsersFilters-module__3gntmhWisf5awBpP3t5Yp2"
};
module.exports = exports;
