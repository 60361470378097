// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".GrafanaTeamSelect-module__23Xr3H0y37v6XQhx9e70nL {\n  width: 200px;\n  right: 0;\n  position: absolute;\n  padding: 16px 0;\n  margin-right: 24px;\n}\n\n.GrafanaTeamSelect-module__1H_aqRppODRDLrQcl3hOvI {\n  display: flex;\n}\n\n.GrafanaTeamSelect-module__2N1r20LFxJEi7P1yP4pGeG {\n  position: absolute;\n  right: 0;\n}\n\n.GrafanaTeamSelect-module__-mWJkLpSnchaKLD1Jkpnu {\n  margin-left: 4px;\n}\n", "",{"version":3,"sources":["GrafanaTeamSelect.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,QAAQ;EACR,kBAAkB;EAClB,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,QAAQ;AACV;;AAEA;EACE,gBAAgB;AAClB","file":"GrafanaTeamSelect.module.css","sourcesContent":[".teamSelect {\n  width: 200px;\n  right: 0;\n  position: absolute;\n  padding: 16px 0;\n  margin-right: 24px;\n}\n\n.teamSelectLabel {\n  display: flex;\n}\n\n.teamSelectLink {\n  position: absolute;\n  right: 0;\n}\n\n.teamSelectInfo {\n  margin-left: 4px;\n}\n"]}]);
// Exports
exports.locals = {
	"teamSelect": "GrafanaTeamSelect-module__23Xr3H0y37v6XQhx9e70nL",
	"teamSelectLabel": "GrafanaTeamSelect-module__1H_aqRppODRDLrQcl3hOvI",
	"teamSelectLink": "GrafanaTeamSelect-module__2N1r20LFxJEi7P1yP4pGeG",
	"teamSelectInfo": "GrafanaTeamSelect-module__-mWJkLpSnchaKLD1Jkpnu"
};
module.exports = exports;
